<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col class="d-flex justify-content-between flex-column md-6">
        <b-row class="align-items-center mb-2">
          <b-col cols="12" md="2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class=""
              @click="$router.go(-1)"
            >
              <feather-icon icon="ArrowLeftIcon" class="mr-25" />
              <span>List</span>
            </b-button>
          </b-col>
          <b-col cols="12" md="6">
            <h4>Update Profile Request Details:</h4>
          </b-col>
          <b-col cols="12" md="6" />
        </b-row>

        <b-row>
          <b-col md="6">
            <b-row>
              <b-col md="6" class="pb-50 font-weight-bold"> Request By </b-col>
              <b-col md="6" class="pb-50">
                {{ userData.request_by_name }}
              </b-col>
            </b-row>
            <br />
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col md="6" class="pb-50 font-weight-bold"> Status </b-col>
              <b-col md="6" class="pb-50">
                {{ userData.status_label }}
              </b-col>
            </b-row>
          </b-col>
          <b-col md="12">
            <b-form-checkbox-group
              id="checkbox-group"
              v-model="selectedUpdateProfileRequests"
              name="selectedUpdateProfileRequests"
            >
              <table class="w-100 table b-table">
                <thead class="">
                  <tr class="text-capitalize">
                    <th>S.No.</th>
                    <th>Field Name</th>
                    <th>Field Last Value</th>
                    <th>Field New Value</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(
                      item, index
                    ) in userData.update_profile_request_fields"
                    v-bind:key="index"
                  >
                    <td>
                      {{ ++index }}
                    </td>
                    <td>
                      {{ item.request_field_name }}
                    </td>
                    <td>
                      {{
                        item.request_field_last_value
                          ? item.request_field_last_value
                          : "NA"
                      }}
                    </td>
                    <td>
                      {{
                        item.request_field_new_value
                          ? item.request_field_new_value
                          : "NA"
                      }}
                    </td>
                    <td>
                      <b-badge
                        pill
                        :variant="`${resolveUserStatusVariant(
                          item.status
                        )}`"
                        class="text-capitalize"
                      >
                        {{ item.status_label }}
                      </b-badge>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-form-checkbox-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-for="(data, index) in customFields" v-bind:key="index" md="6">
        <b-row>
          <b-col md="6" class="pb-50 font-weight-bold">
            {{ data.label }}
          </b-col>
          <b-col md="6" class="pb-50">
            {{ userData.get_customfields_data["field_" + data.id] }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BButton, VBTooltip } from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import Vue from "vue";
import VueCookies from "vue-cookies";
import { BTableLite } from "bootstrap-vue";
Vue.use(VueCookies);

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTableLite,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    customFields: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      userDataLocal: this.$cookies.get("userData"),
      selectedUpdateProfileRequests: [],
    };
  },
  methods: {
    openWindow(link) {
      window.open(link);
    },
    resolveUserStatusVariant(status) {
      if (status == 0) return "warning";
      if (status == 1) return "success";
      if (status == 2) return "danger";
      if (status == 3) return "info";
      return "primary";
    },
  },
};
</script>

<style>
</style>
